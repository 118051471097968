
//import { datepicker } from 'js-datepicker'

const horarioCompleto = ["9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00",];
const horarioReducido = ["9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30"];

const datepicker = window.datepicker
const optionsDatePicker = {
    formatter: (input, date, instance) => {
        const value = date.toLocaleDateString()
        input.value = value // => '1/1/2099'
    },
    startDay: 1,
    customMonths: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    customDays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    minDate: new Date(),
    disabler: date => date.getDay() === 0,
    onSelect: (instance, date) => {
        updateHora(date.getDay())
    }
}

const updateHora = (dia) => {
    const horario = dia === 6 ? horarioReducido : horarioCompleto;
    const hora = document.getElementById("hora");
    hora.innerHTML = "";
    horario.forEach(h => {
        const option = document.createElement("option");
        option.value = h;
        option.innerHTML = h;
        hora.appendChild(option);
    })

}

const fechaInput = document.getElementById("fecha")
const picker = datepicker(fechaInput, optionsDatePicker)

document.getElementById("send").addEventListener("click", validateForm);
function validateForm() {
    const formError = document.getElementById("formError");
    formError.innerHTML = "";
    const nombre = document.getElementById("nombre").value;
    const email = document.getElementById("email").value;
    const telefono = document.getElementById("telefono").value;
    const mensaje = document.getElementById("mensaje").value;
    const fecha = document.getElementById("fecha").value;
    const hora = document.getElementById("hora").value;
    if(nombre.trim() == ""){
        formError.innerHTML = "El campo nombre es obligatorio";
    } else if(email.trim() == "" && telefono.trim() === ""){
        formError.innerHTML = "El campo email o telefono es obligatorio";
    } else if(mensaje.trim() == ""){
        formError.innerHTML = "El campo mensaje es obligatorio";
    }else if(fecha.trim() === ""){
        formError.innerHTML = "El campo fecha es obligatorio";
    }
    else if(hora.trim() === ""){
        formError.innerHTML = "El campo hora es obligatorio";
    } else{
        try {
            sendMessage(nombre, email, telefono, fecha, hora, mensaje);
            formError.innerHTML = "Mensaje enviado";
        } catch (error) {
            console.log(error)
            formError.innerHTML = "Error al enviar el mensaje";
        }
    }
}

async function sendMessage(nombre, email, telefono, fecha, hora, mensaje) {
    const url = "https://europe-west1-peluqueria-aranxa-madrid.cloudfunctions.net/sendMail";
    const result = await fetch(url + `?nombre=${nombre}&email=${email}&telefono=${telefono}&mensaje=${mensaje}&fecha=${fecha}&hora=${hora}`)
    return result
}


function getCookie(c_name){
    var c_value = document.cookie;
    var c_start = c_value.indexOf(' ' + c_name + '=');
    if (c_start == -1){
        c_start = c_value.indexOf(c_name + '=');
    }
    if (c_start == -1){
        c_value = null;
    }else{
        c_start = c_value.indexOf('=', c_start) + 1;
        var c_end = c_value.indexOf(';', c_start);
        if (c_end == -1){
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start,c_end));
    }
    return c_value;
}
function setCookie(c_name,value,exdays){
    var exdate=new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value=escape(value) + ((exdays==null) ? '' : '; expires='+exdate.toUTCString());
    document.cookie=c_name + '=' + c_value;
}

document.addEventListener('click', function (event) {
    if (!event.target.matches('.okCookies')) return;
    aceptarCookies();
	event.preventDefault();
}, false);

function aceptarCookies() {
    setCookie('cookies','1',365);
    document.getElementById('cookies').style.display='none';
}

function comprobarCookies(){
    var cookies = getCookie('cookies');
    if(cookies == '1' ){
        document.getElementById('cookies').style.display='none';
    }else{
        document.getElementById('cookies').style.display='block';
    }
}

window.onload = function(){
    comprobarCookies();
}